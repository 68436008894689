<template>
	<div class="wordpress">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12">
					<div class="row">
						<div class="col-lg-8">
							<card v-if="apiKey === null">
								<div class="loading">
									<i class="fa fa-spinner fa-spin" />
									<span>{{ $t('wordpress.loading') }}</span>
								</div>
							</card>

							<card v-else>
								<div v-if="apiKey.verifiedAt !== null"
									 class="status verified">
									<div class="icon">
										<i class="fa fa-check fa-fw" />
									</div>

									<div class="meta">
										<div class="title">{{ $t('wordpress.verified.title') }}</div>
										<div class="text" v-html="$t('wordpress.verified.text', {domain: apiKey.domain})" />
									</div>
								</div>

								<template v-else>
									<div class="status unverified">
										<div class="icon">
											<i class="fa fa-times fa-fw" />
										</div>

										<div class="meta">
											<div class="title">{{ $t('wordpress.unverified.title') }}</div>
											<div class="text">{{ $t('wordpress.unverified.text') }}</div>
										</div>
									</div>

									<div class="guide">
										<h2>{{ $t('wordpress.guide.title') }}</h2>

										<ol>
											<li v-html="$t('wordpress.guide.step1')" />
											<li v-html="$t('wordpress.guide.step2')" />
											<li v-html="$t('wordpress.guide.step3')" />
											<li v-html="$t('wordpress.guide.step4')" />
										</ol>
									</div>
								</template>

								<strong style="display: block; margin-bottom: 10px;">{{ $t('wordpress.apiKey.title') }}:</strong>

								<div class="apiKey">
									<div class="value" :class="{ h: ! revealed }">
										<template v-if="revealed">{{ apiKey.apiKey }}</template>
										<template v-if="! revealed">***************</template>
									</div>

									<div class="actions">
										<button @click="regenerate"
												type="button">
											<i v-if="generating" class="fa fa-spinner fa-spin fa-fw" />
											<i v-else class="fa fa-rotate-right fa-fw" />
										</button>

										<button @click="revealed = ! revealed"
												type="button">
											<i v-if="revealed" class="fa fa-eye fa-fw" />
											<i v-else class="fa fa-eye-slash fa-fw" />
										</button>
									</div>
								</div>
							</card>
						</div>

						<div class="col-lg-4">
							<need-help />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.loading {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	i {
		font-size: 16px;
	}

	span {
		font-size: 13px;
	}
}

.apiKey {
	position: relative;
	height: 45px;
	border-radius: 8px;
	padding: 0 15px;
	border: 1px solid #eee;

	> div.value {
		display: flex;
		align-items: center;
		height: 45px;

		font-size: 15px;

		&.h {
			font-weight: 300;
			font-size: 32px;
			padding-top: 10px;
			letter-spacing: 8px;
		}
	}

	.actions {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		padding: 0 15px;

		button {
			color: #000 !important;
			height: 100%;
			font-size: 16px;
			padding: 0 5px;
			outline: 0;
			border: 0;
			background: transparent;
		}
	}
}

.status {
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 20px;

	> div.icon {
		display: flex;
		align-items: center;
		justify-content: center;

		border-radius: 3px;

		width: 60px;
		height: 60px;
		font-size: 20px;
	}

	> div.meta {
		display: flex;
		flex-direction: column;

		> div.title {
			font-size: 15px;
			font-weight: 500;
		}

		> div.text {
			color: #4f4f4f;
			font-weight: 400;
			font-size: 14px;
		}
	}

	&.verified {
		> div.icon {
			color: #54b23f;
			background-color: #e9f1e9;
		}
	}

	&.unverified {
		> div.icon {
			color: #b23f3f;
			background-color: #ffe1e1;
		}
	}
}

.guide {
	margin-bottom: 20px;

	h2 {
		color: #000;
		font-size: 15px;
		font-weight: 600;
		margin: 0;
		text-decoration: underline;
	}

	ol {
		margin: 10px 0 0 20px;
		padding: 0;

		li {
			padding: 4px 0;

			::v-deep a {
				font-weight: 500;
				text-decoration: underline;
			}
		}
	}
}
</style>

<script>
import NeedHelp from '@/app/integrations/components/NeedHelp'

import WordPressService from '@/services/wordpress/WordPressService'

export default {
	data: () => ({
		apiKey: null,

		generating: false,
		revealed: false
	}),

	mounted() {
		this.load()
	},

	methods: {
		load() {
			WordPressService.get(
				{},
				({ data }) => {
					this.apiKey = data
				},
				(response) => {
				}
			)
		},

		regenerate() {
			if (this.generating) {
				return
			}

			this.generating = true

			WordPressService.generate(
				{},
				({ data }) => {
					this.apiKey = data
					this.generating = false
				},
				() => {
					this.generating = false
				}
			)
		}
	},

	components: {NeedHelp}
}
</script>
